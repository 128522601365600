import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../components/button';
import AlertTP from '../../../../components/tpDetails/alert';
import NoResult from '../../../../components/NoResult';

import Route from './Route';
import Voucher from './Voucher';
import Tariff from './Tariff';
import UploadFile from './UploadFile';

import {
  FIELDS, VOUCHER_TEXT, TRIPSLABELS,
} from '../../../../bi/constants/trips';

import {
  findDepartments,
  makeTrainJsonData,
  makeCopyTrainJsonData,
  makeTrainDatesToMoment,
  getSumPrices,
  checkIsKaliningradTicket,
} from '../../../../bi/utils/trip';
import {
  applyAnalytics,
  removeAnalytics,
  validateAnalytics,
  validateSingleAnalytics,
} from '../../../../bi/utils/customAnalytics';
import { isMandatory } from '../../../../bi/utils/account';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';
import lodashReplaces from '../../../../bi/utils/lodashReplaces';

import style from '../../styles/form.module.scss';

const INVALID_FORM_MESSAGE = 'Проверьте корректность ввода данных';

class NewTrainForm extends Component {
  static propTypes = {
    train: PropTypes.object.isRequired,
    companies: PropTypes.array,
    tripService: PropTypes.object,
    travelPolicyService: PropTypes.object.isRequired,
    projects: PropTypes.array,
    mandatoryProject: PropTypes.array,
    isEdit: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    isCopy: PropTypes.bool,
    addTrip: PropTypes.func.isRequired,
    guidService: PropTypes.object.isRequired,
    noOrder: PropTypes.bool,
    partEditItem: PropTypes.bool,
  };

  static defaultProps = {
    tripService: [],
    projects: [],
    companies: [],
    isEdit: false,
    mandatoryProject: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    isCopy: false,
    noOrder: false,
    partEditItem: false,
  };

  constructor(props) {
    super(props);
    const {
      train, tripService, isEdit, partEditItem, isCopy,
    } = props;

    if (train.isEdit) {
      train.JsonData.Route = makeTrainDatesToMoment(train.JsonData.Route);
      this.state = train;
      tripService.updateFileWithoutReq(train.JsonData.guid, train.file.File);
      tripService.changeField(train.JsonData, FIELDS.JSON_DATA);

      return;
    }

    if (isEdit) {
      const json = JSON.parse(train.JsonData);
      tripService.changeField(makeTrainJsonData(json), FIELDS.JSON_DATA);
    }

    if (isCopy) {
      tripService.changeField(makeCopyTrainJsonData(train.JsonData), FIELDS.JSON_DATA);
    }

    if (partEditItem) {
      const json = JSON.parse(train.JsonData);
      const paths = getSumPrices(json);

      tripService.changeFields(paths);
    }

    const {
      validation, JsonData, Description,
    } = tripService.getTrainData();

    const CompanyId = train.CompanyId || 0;
    const DepartmentId = train.DepartmentId || 0;
    const EmployeeId = (train.Employee && train.Employee.Id) || 0;
    const ProjectId = train.ProjectId || 0;

    this.state = {
      OrderTripItemId: train.OrderTripItemId,
      ServiceType: train.ServiceType,
      Status: isEdit ? 1 : 0,
      Employee: train.Employee,
      CompanyId,
      EmployeeId,
      JsonData,
      ProjectId,
      DepartmentId,
      Departments: [],
      TicketId: '',
      tpDetails: null,
      showAlertTP: false,
      checkValid: true,
      validation,
      Description,
      isEmployeeHasntPassport: false,
      employeesList: [],
      analyticsValidation: {},
      mandatoryProject: [],
    };
  }

  componentDidMount() {
    const {
      tripService, isEdit, train,
    } = this.props;

    this.unsubscribeTrainData = tripService.subscribeTrain(this.updateTrainData);
    this.unsubscribe = tripService.subscribe(this.updateTripData);

    if (isEdit) {
      this.setDepartment();
    }

    this.updateAnalytics(train);
  }

  componentWillUnmount() {
    const { tripService } = this.props;

    tripService.resetTrainStore();
    this.unsubscribeTrainData();
    this.unsubscribe();
  }

  updateTripData = ({ employeesList }) => this.setState({ employeesList });

  updateTrainData = ({
    JsonData, validation, Description,
  }) => {
    this.setState({
      JsonData,
      validation,
      Description,
    });
  };

  updateAnalytics = ({ UserAnalytics = [] }) => {
    this.setState({ UserAnalytics });
  };

  setDepartment = async () => {
    const { train: { Employee: { Id }, CompanyId }, tripService: { getEmployeeDataByID } } = this.props;
    const currentEmployee = await getEmployeeDataByID(Id);

    this.setState({ Departments: findDepartments(currentEmployee, CompanyId) });
  };

  prepareJsonData = (JsonData, isKaliningrad) => {
    if (isKaliningrad) {
      return JsonData;
    }

    return lodashReplaces.omit(JsonData, FIELDS.TRANSIT_DOCUMENT_TYPE);
  };

  handleTrainConfirm = () => {
    const {
      OrderTripItemId,
      CompanyId,
      ServiceType,
      Status,
      JsonData,
      Employee,
      ProjectId,
      DepartmentId,
      Departments,
      Description,
      UserAnalytics: serviceAnalyticsValues,
    } = this.state;

    const {
      guidService,
      train,
      addTrip,
      analytics,
      tripService,
      onConfirm,
      isEdit,
      tripAnalyticsValueIds: tripAnalyticsValues,
      projects,
      partEditItem,
      mandatoryProject,
    } = this.props;
    let projectId;
    let newMandatoryFlag;
    if (CompanyId && !isMandatory(mandatoryProject, CompanyId)) {
      projectId = ProjectId || true;
      newMandatoryFlag = false;
    } else {
      projectId = ProjectId;
      newMandatoryFlag = true;
    }
    const departmentId = Departments.length > 0 ? DepartmentId : true;
    const description = isEdit ? Description : true;

    const isKaliningrad = checkIsKaliningradTicket(JsonData.Route.StationDepart, JsonData.Route.StationArrive);
    const validatedJsonData = this.prepareJsonData(JsonData, isKaliningrad);

    const forChekValid = {
      Employees: Employee.Id,
      CompanyId,
      ProjectId: projectId,
      DepartmentId: departmentId,
      Description: partEditItem ? '' : description,
      ProviderName: JsonData && JsonData.ProviderName ? JsonData.ProviderName : null,
      ...validatedJsonData,
    };

    const { isValid, validation } = tripService.trainTripFieldsValidation(
      forChekValid, newMandatoryFlag, projects, partEditItem,
    );
    const userAnalytics = validateAnalytics(tripAnalyticsValues, serviceAnalyticsValues, analytics);
    const hasUnsetRequiredAnalytics = Object.keys(userAnalytics).some((key) => !!userAnalytics[key]);
    this.setState({ analyticsValidation: userAnalytics });

    if (isValid && !hasUnsetRequiredAnalytics) {
      const Guid = guidService.generate();
      const stateToSave = JSON.parse(JSON.stringify(this.state));
      stateToSave.Guid = Guid;
      stateToSave.file = tripService.getTrainData().file;

      JsonData.EmployeeIds = [Employee.Id];

      let preparedJson = tripService.normalizeTrainJson(JsonData, partEditItem);

      if (train.JsonData) {
        const jsonData = typeof train.JsonData === 'string' ? JSON.parse(train.JsonData) : train.JsonData;

        preparedJson = { ...jsonData, ...tripService.normalizeTrainJson(JsonData, partEditItem) };
      }

      const validatedJson = this.prepareJsonData(preparedJson, isKaliningrad);

      const item = {
        OrderTripItemId,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),

        Description,
        Status,
        ServiceType,

        JsonData: JSON.stringify(validatedJson),

        UserAnalytics: serviceAnalyticsValues,
        Guid,
        indexEdited: train.indexEdited,
      };

      this.setState({ checkValid: true });
      tripService.resetTrainStore();

      if (!partEditItem) addTrip(stateToSave);

      onConfirm(item);
    } else {
      scrollToErrMessage();
      tripService.changeField(validation, FIELDS.VALIDATION);

      this.setState({ checkValid: false });
    }
  };

  handleCloseModal = () => this.setState({ isEmployeeHasntPassport: false });

  handleRemoveEmployee = () => {
    const { tripService } = this.props;
    tripService.changeField(0, `${FIELDS.JSON_DATA}.${FIELDS.PASSPORT_ID}`);

    this.setState({
      Employee: {},
      tpDetails: null,
      Departments: [],
      EmployeeId: 0,
      DepartmentId: 0,
    });
  };

  handleSelectCompany = ({ id }) => {
    const {
      tripService, mandatoryProject, projects,
    } = this.props;
    const newMandatory = isMandatory(mandatoryProject, id);
    const result = tripService.trainTripFieldValidation(FIELDS.COMPANYID, id);

    const emptyProjects = projects.length
      ? tripService.trainTripFieldValidation(FIELDS.PROJECTID, id)[0]
      : tripService.trainTripFieldValidation(FIELDS.PROJECTID, id)[1];

    const projectResult = newMandatory
      ? emptyProjects
      : '';

    tripService.changeField(projectResult, `${FIELDS.VALIDATION}.${FIELDS.PROJECTID}`);

    const paths = [
      { value: result, path: `${FIELDS.VALIDATION}.${FIELDS.COMPANYID}` },
      { value: '', path: `${FIELDS.VALIDATION}.${FIELDS.EMPLOYEES}` },
    ];

    tripService.changeFields(paths);

    this.setState({
      mandatoryProject: newMandatory,
      CompanyId: id,
      Employees: {},
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService, projects } = this.props;
    const { mandatoryProject } = this.state;

    const emptyProjects = projects.length
      ? tripService.trainTripFieldValidation(FIELDS.PROJECTID, id)[0]
      : tripService.trainTripFieldValidation(FIELDS.PROJECTID, id)[1];
    const result = mandatoryProject
      ? emptyProjects
      : '';

    tripService.changeField(result, `${FIELDS.VALIDATION}.${FIELDS.PROJECTID}`);

    this.setState({ ProjectId: id });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [] } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      analyticsValidation: {
        ...this.state.analyticsValidation,
        ...analyticsValidation,
      },
    });
  };

  getTP = async (employee) => {
    const { travelPolicyService } = this.props;
    const TRAIN = this.props.travelPolicyService.getServiceTypeConst().TRAIN;

    try {
      const travelPolicy = await travelPolicyService.getEmployeeTP(employee.Id);
      const { Value: rate } = await travelPolicyService.getRateForType(travelPolicy.TrainRule);

      const tpDetails = travelPolicyService.getTPDetails(travelPolicy, TRAIN, rate);

      this.setState({
        tpDetails,
        showAlertTP: !!tpDetails,
      });
    } catch (error) {
      this.setState({ tpDetails: null });
    }
  };

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  };

  handleSelectEmployee = async (id) => {
    const { tripService } = this.props;

    const currentEmployee = await tripService.getEmployeeDataByID(id);
    const { CompanyId } = this.state;

    this.setState({
      Departments: findDepartments(currentEmployee, CompanyId),
      EmployeeId: id,
    });

    if (currentEmployee.Documents.length) {
      const validationEmployees = tripService.trainTripFieldValidation(FIELDS.EMPLOYEES, currentEmployee);
      const pasportId = Number(currentEmployee.Documents[0].Id);

      tripService.changeField(pasportId, `${FIELDS.JSON_DATA}.${FIELDS.PASSPORT_ID}`);
      tripService.changeField(validationEmployees, `${FIELDS.VALIDATION}.${FIELDS.EMPLOYEES}`);

      this.setState({ Employee: currentEmployee }, () => this.getTP(currentEmployee));
    } else {
      this.setState({ isEmployeeHasntPassport: true });
    }
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;
    const result = Departments.length ? tripService.trainTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    tripService.changeField(result, `${FIELDS.VALIDATION}.${FIELDS.DEPARTMENTID}`);

    this.setState({ DepartmentId: id });
  };

  closeAlertTP = () => this.setState({ showAlertTP: false });

  renderAlertTP = () => (this.state.showAlertTP ? <AlertTP onClose={ this.closeAlertTP } /> : null);

  renderEmployeeHasNotPassortModal = () => {
    const { isEmployeeHasntPassport } = this.state;

    return isEmployeeHasntPassport && (
      <NoResult
        onClose={ this.handleCloseModal }
        message='Нельзя выбрать сотрудника без паспорта'
      />
    );
  };

  handleEmployeeFocus = async () => {
    const { tripService } = this.props;
    const { CompanyId } = this.state;

    await tripService.autocompleteEmployees(CompanyId, '');

    const employees = parseInt(CompanyId, 10) ? '' : VOUCHER_TEXT.PICK_COMPANY_FIRST;
    tripService.changeField(employees, `${FIELDS.VALIDATION}.${FIELDS.EMPLOYEES}`);
  };

  render() {
    const {
      Employee,
      EmployeeId,
      Departments,
      DepartmentId,
      CompanyId,
      validation,
      JsonData,
      tpDetails,
      ProjectId,
      checkValid,
      UserAnalytics,
      analyticsValidation,
      employeesList,
    } = this.state;
    const {
      companies,
      projects,
      isEdit,
      tripService,
      analytics,
      tripAnalyticsValueIds,
      noOrder,
      mandatoryProject,
      partEditItem,
      train,
      isCopy,
    } = this.props;

    const message = checkValid ? '' : INVALID_FORM_MESSAGE;
    const propsForVoucher = {
      Employee,
      EmployeeId,
      Departments,
      DepartmentId,
      CompanyId,
      validation,
      JsonData,
      tpDetails,
      employees: employeesList,
      companies,
      ProjectId,
      projects,
    };

    return (
      <div className={ style.wrap } >
        <Voucher
          propsForVoucher={ propsForVoucher }
          onSelectCompany={ this.handleSelectCompany }
          onSelectProject={ this.handleSelectProject }
          onSelectEmployee={ this.handleSelectEmployee }
          onEmployeeFocus={ this.handleEmployeeFocus }
          onChangeEmployee={ this.handleChangeEmployee }
          onRemoveEmployee={ this.handleRemoveEmployee }
          onSelectDepartment={ this.handleSelectDepartment }
          tripService={ tripService }
          isEdit={ isEdit }
          analytics={ analytics }
          mandatoryProject={ mandatoryProject }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          serviceAnalyticsValueIds={ UserAnalytics }
          handleSelectAnalytics={ this.handleSelectAnalytics }
          analyticsValidation={ analyticsValidation }
          noOrder={ noOrder }
          partEditItem={ partEditItem }
          train={ train }
        />
        <Route
          tripService={ tripService }
          partEditItem={ partEditItem }
          train={ train }
          isCopy={ isCopy }
        />
        <Tariff
          tripService={ tripService }
        />
        <UploadFile tripService={ tripService } />
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <FlatButton
              onClick={ this.handleTrainConfirm }
              label={ TRIPSLABELS.CONFIRM }
              inValidMessage={ message }
              withWrap
            />
          </div>
        </div>
        { this.renderAlertTP() }
        { this.renderEmployeeHasNotPassortModal() }
      </div>
    );
  }
}

export default NewTrainForm;
